import React, { useState } from 'react';

import type { ColorChangeHandler, RGBColor } from 'react-color';

import { FlexBox } from '@eltoro-ui/components';

import Picker from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/BannerBackgroundColorPicker/components/Picker';
import ColorField from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/BannerBackgroundColorPicker/components/ColorField';

import { colors } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/BannerBackgroundColorPicker/constants';

import './BannerBackgroundColorPicker.scss';

const colorPickerRGBToStringRGB = (color: RGBColor) =>
  `rbg(${(Object.values(color) as number[]).join(', ')})`;

interface BannerBackgroundColorPickerProps {
  selectedColor: string;
  onSelectColor: (color: string) => void;
}

export default function BannerBackgroundColorPicker({
  selectedColor,
  onSelectColor,
}: BannerBackgroundColorPickerProps) {
  const [colorPickerValue, setColorPickerValue] = useState<RGBColor>({ r: 207, g: 228, b: 236 });

  const onChangeColorPicker: ColorChangeHandler = color => {
    const rgbString = colorPickerRGBToStringRGB(color.rgb);
    setColorPickerValue(color.rgb);
    onSelectColor(rgbString);
  };

  return (
    <FlexBox flexDirection="column" gap="1rem" UNSAFE_className="banner-background-wrapper">
      <span className="banner-background-wrapper-label">Choose Banner Background Color</span>
      <FlexBox alignItems="center" gap="10px">
        {colors.map(color => (
          <ColorField
            key={color}
            color={color}
            isSelected={color === selectedColor}
            onSelectColor={onSelectColor}
          />
        ))}
        <Picker
          isSelected={selectedColor === colorPickerRGBToStringRGB(colorPickerValue)}
          color={colorPickerValue}
          onChangeColorPicker={onChangeColorPicker}
        />
      </FlexBox>
    </FlexBox>
  );
}
