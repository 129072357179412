import React, { createContext, useContext, useEffect, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

import { useSelector } from 'react-redux';

import {
  steps,
  StepType,
} from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/constants';
import { colors } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/BannerBackgroundColorPicker/constants';

import { userDetail } from 'Requests/Request_Methods/authUserMethods';

import type { CampaignGoalType } from 'Utils/constants';
import { CampaignGoals } from 'Utils/constants';

import { GoalSectionTitles } from 'enums';
import type { TGeneratedBanner, TRootState } from 'types';

interface ICreativeFormStateType {
  agentPhoto: File | null;
  listingPhoto: File | null;
  agencyLogo: File | null;
  agent_name: string;
  agent_phone: string;
  real_estate_agency: string;
}

interface IListingGaol extends ICreativeFormStateType {
  type: 'listing';
  listing_address: string;
  bed: string;
  bath: string;
  sqft: string;
}
interface IAgentGaol extends ICreativeFormStateType {
  type: 'agent';
  agent_word: string;
  team_slogan: string;
  background_color: string;
}

interface IDefaultUserFormStateData
  extends Pick<ICreativeFormStateType, 'agent_name' | 'agent_phone' | 'agentPhoto'> {}

type MagicCreativeContextType = {
  formState: [
    state: IListingGaol | IAgentGaol,
    setter: Dispatch<SetStateAction<IListingGaol | IAgentGaol>>
  ];
  isValidForm: [state: boolean, setter: Dispatch<SetStateAction<boolean>>];
  generateHasError: [state: boolean, setter: Dispatch<SetStateAction<boolean>>];
  useGeneratedBannersLoading: [state: boolean, setter: Dispatch<SetStateAction<boolean>>];
  generateLoading: [state: boolean, setter: Dispatch<SetStateAction<boolean>>];
  generatedBanners: [
    state: TGeneratedBanner[],
    setter: Dispatch<SetStateAction<TGeneratedBanner[]>>
  ];
  selectedGoal: CampaignGoalType;
  activeStep: StepType;
  onStepChange: (step: StepType) => void;
  onSelectGoal: (goal: CampaignGoalType) => void;
  clearAllStates: VoidFunction;
  defaultUserDataLoading: boolean;
};

const defaultAgentGoalValue: IAgentGaol = {
  type: 'agent',
  agent_word: '',
  team_slogan: '',
  real_estate_agency: '',
  agentPhoto: null,
  listingPhoto: null,
  agencyLogo: null,
  agent_name: '',
  agent_phone: '',
  background_color: colors[0],
};

const defaultListingGoalValue: IListingGaol = {
  type: 'listing',
  listing_address: '',
  real_estate_agency: '',
  bath: '',
  bed: '',
  sqft: '',
  agentPhoto: null,
  listingPhoto: null,
  agencyLogo: null,
  agent_name: '',
  agent_phone: '',
};

const defaultValue: MagicCreativeContextType = {
  formState: [defaultAgentGoalValue, () => undefined],
  isValidForm: [false, () => false],
  generateHasError: [false, () => false],
  useGeneratedBannersLoading: [false, () => false],
  generateLoading: [false, () => false],
  generatedBanners: [[], () => []],
  selectedGoal: CampaignGoals[0],
  activeStep: steps[0],
  onStepChange: () => undefined,
  onSelectGoal: () => undefined,
  clearAllStates: () => undefined,
  defaultUserDataLoading: false,
};

const urlToObject = async (image: string) => {
  const response = await fetch(image);
  // here image is url/location of image
  const blob = await response.blob();
  return new File([blob], 'image.jpg', { type: blob.type });
};

const MagicCreativeContext = createContext<MagicCreativeContextType>(defaultValue);

export const useMagicCreativeContext = () => useContext(MagicCreativeContext);

const agentFormGoals = [GoalSectionTitles.CONQUEST_LEADS, GoalSectionTitles.RETENTION];

interface MagicCreativeContextProviderProps {
  children: ReactNode;
}

export default function MagicCreativeContextProvider({
  children,
}: MagicCreativeContextProviderProps) {
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  const [defaultUserData, setDefaultUserData] = useState<IDefaultUserFormStateData>({
    agent_phone: '',
    agent_name: '',
    agentPhoto: null,
  });
  const [defaultUserDataLoading, setDefaultUserDataLoading] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [formState, setFormState] = useState<IListingGaol | IAgentGaol>(defaultAgentGoalValue);
  const [selectedGoal, setSelectedGoal] = useState<CampaignGoalType>(CampaignGoals[0]);

  const [activeStep, setActiveStep] = useState(steps[0]);

  const [generateHasError, setGenerateHasError] = useState(false);
  const [useGeneratedBannersLoading, setUseGeneratedBannersLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [generatedBanners, setGeneratedBanners] = useState<TGeneratedBanner[]>([]);

  useEffect(() => {
    const setUserDefaultValues = async () => {
      try {
        setDefaultUserDataLoading(true);
        const { data: user } = await userDetail(localStorage.getItem('beewo_token') as string);
        if (user) {
          let defaultUserForm: IDefaultUserFormStateData = {
            agent_name: `${user.first_name} ${user.last_name}`,
            agent_phone: user.phone_number,
            agentPhoto: null,
          };
          if (user.profile_pic) {
            defaultUserForm.agentPhoto = await urlToObject(user.profile_pic);
          }

          setDefaultUserData(defaultUserForm);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setDefaultUserDataLoading(false);
      }
    };
    setUserDefaultValues();
  }, [userData]);

  useEffect(() => {
    if (agentFormGoals.includes(selectedGoal.name))
      setFormState({ ...defaultAgentGoalValue, ...defaultUserData });
    else setFormState({ ...defaultListingGoalValue, ...defaultUserData });
  }, [selectedGoal, defaultUserData]);

  const clearAllStates = () => {
    const defaultFormState = agentFormGoals.includes(selectedGoal.name)
      ? defaultAgentGoalValue
      : defaultListingGoalValue;

    setFormState({ ...defaultFormState, ...defaultUserData });
    setGeneratedBanners([]);
    setGenerateLoading(false);
    setUseGeneratedBannersLoading(false);
    setGenerateHasError(false);
  };

  const onSelectGoal = (goal: CampaignGoalType) => setSelectedGoal(goal);

  const onStepChange = (step: StepType) => setActiveStep(step);

  return (
    <MagicCreativeContext.Provider
      value={{
        formState: [formState, setFormState],
        isValidForm: [isValidForm, setIsValidForm],
        generateLoading: [generateLoading, setGenerateLoading],
        useGeneratedBannersLoading: [useGeneratedBannersLoading, setUseGeneratedBannersLoading],
        generatedBanners: [generatedBanners, setGeneratedBanners],
        generateHasError: [generateHasError, setGenerateHasError],
        selectedGoal,
        onSelectGoal,
        activeStep,
        onStepChange,
        clearAllStates,
        defaultUserDataLoading,
      }}
    >
      {children}
    </MagicCreativeContext.Provider>
  );
}
