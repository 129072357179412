import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeadset,
  faInfoCircle,
  faPauseCircle,
  faPlayCircle,
} from '@fortawesome/pro-light-svg-icons';

import CustomIcons from 'assets/icons';

import { OrderlineActionsEnum, OrderlineStatusEnum } from 'enums';

export const orderlineActionItems = [
  {
    accessStatuses: [
      OrderlineStatusEnum.IN_REVIEW,
      OrderlineStatusEnum.REJECTED,
      OrderlineStatusEnum.APPROVED,
      OrderlineStatusEnum.ACTIVE,
      OrderlineStatusEnum.PAUSED,
    ],
    label: OrderlineActionsEnum.RENAME,
    icon: <CustomIcons name="edit" color="#222124" fontSize={12} />,
  },
  {
    accessStatuses: [OrderlineStatusEnum.PAUSED],
    flightDatePassed: false,
    label: OrderlineActionsEnum.RESUME,
    icon: <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: 12 }} />,
  },
  // {
  //   accessStatuses: [
  //     OrderlineStatusEnum.REJECTED,
  //     OrderlineStatusEnum.APPROVED,
  //     OrderlineStatusEnum.ACTIVE,
  //     OrderlineStatusEnum.PAUSED,
  //   ],
  //   flightDatePassed: false,
  //   label: OrderlineActionsEnum.EDIT_DATES,
  //   icon: <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 12 }} />,
  // },
  {
    accessStatuses: [OrderlineStatusEnum.ACTIVE],
    label: OrderlineActionsEnum.PAUSE,
    icon: <FontAwesomeIcon icon={faPauseCircle} style={{ fontSize: 12 }} />,
  },
  {
    accessStatuses: [OrderlineStatusEnum.PAUSED],
    flightDatePassed: true,
    label: OrderlineActionsEnum.CONTACT_SUPPORT,
    icon: <FontAwesomeIcon icon={faHeadset} style={{ fontSize: 12 }} />,
  },
  {
    accessStatuses: [
      OrderlineStatusEnum.IN_REVIEW,
      OrderlineStatusEnum.REJECTED,
      OrderlineStatusEnum.APPROVED,
      OrderlineStatusEnum.ACTIVE,
      OrderlineStatusEnum.PAUSED,
      OrderlineStatusEnum.COMPLETED,
    ],
    label: OrderlineActionsEnum.DOWNLOAD_PDF,
    icon: <CustomIcons name="download" fontSize={12} />,
  },
];
