import React from 'react';
import type { Dispatch, SetStateAction } from 'react';

import classNames from 'classnames';
import moment from 'moment/moment';

import Tooltip from 'rc-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

import { FlexBox } from '@eltoro-ui/components';

import StatusTag from 'Pages/OrderlineDetails/components/StatusTag';
import OrderlineActions from 'Pages/OrderlineDetails/components/OrderlineHeader/components/OrderlineActions';

import { OrderlineStatusEnum } from 'enums';
import type { TOrderlineDetails } from 'types';

interface OrderlineHeaderProps
  extends Pick<TOrderlineDetails, 'name' | 'status' | 'end_date' | 'start_date'> {
  setOrderline: Dispatch<SetStateAction<TOrderlineDetails | null>>;
  getOrderline: () => Promise<void>;
  generatePDF: () => Promise<void>;
}

export default function OrderlineHeader({
  name,
  status,
  start_date,
  end_date,
  setOrderline,
  getOrderline,
  generatePDF,
}: OrderlineHeaderProps) {
  const endDate = moment(end_date);
  const startDate = moment(start_date);

  const flightDates = `${endDate.format('MM/DD/YYYY')} - ${startDate.format('MM/DD/YYYY')}`;

  return (
    <FlexBox
      alignItems="center"
      justifyContent="space-between"
      UNSAFE_className="orderline-details-header"
    >
      <FlexBox flexDirection="column" gap="12px">
        <FlexBox alignItems="center" gap="12px">
          <span className="orderline-details-name">{name}</span>
          {status === OrderlineStatusEnum.PAUSED && endDate.isBefore(moment()) && (
            <Tooltip
              placement="topLeft"
              trigger="hover"
              overlayClassName={classNames('audience-tooltip', 'save-audience-info')}
              showArrow
              align={{
                offset: [-15, -3],
                targetOffset: [-6, 0],
              }}
              overlay="You orderline end date is passed, Please contact support at help@beewo.com for assistance."
              getTooltipContainer={() => document.body}
            >
              <span style={{ cursor: 'pointer', display: 'flex' }}>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ fontSize: 18, color: '#FFAB03' }}
                />
              </span>
            </Tooltip>
          )}
          <StatusTag status={status} />
        </FlexBox>
        <span className="orderline-details-flight_dates">
          Flight Dates: <b>{flightDates}</b>
        </span>
      </FlexBox>
      <OrderlineActions
        name={name}
        status={status}
        end_date={end_date}
        setOrderline={setOrderline}
        getOrderline={getOrderline}
        generatePDF={generatePDF}
      />
    </FlexBox>
  );
}
