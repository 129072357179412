import React, { useMemo } from 'react';

import { PieChart } from 'react-minimal-pie-chart';

import { FlexBox } from '@eltoro-ui/components';

import {
  EPerformanceChartStat,
  performanceStatChartColors,
} from 'Pages/OrderlineDetails/components/PerformanceMetrics/components/PerformanceStatChart/constants';

import { compactNumberConverter } from 'Utils/helpers';

import '../PerformanceStat/PerformanceStat.scss';

interface PerformanceStatChartProps {
  name: EPerformanceChartStat;
  stats: Record<string, number>;
  pieValue: number;
}

export default function PerformanceStatChart({ name, stats, pieValue }: PerformanceStatChartProps) {
  const data = { value: pieValue, color: performanceStatChartColors[name][0] };

  const totalValue = useMemo(
    () => Object.values(stats).reduce((total, value) => total + value, 0),
    [stats]
  );

  return (
    <FlexBox
      alignItems="center"
      justifyContent="space-between"
      gap="12px"
      UNSAFE_className="performance-stat-container"
    >
      <FlexBox flexDirection="column" gap="0.75rem">
        {Object.entries(stats).map(([stat, value], index) => (
          <FlexBox key={stat} alignItems="center" gap="10px">
            <div
              className="stat-chart-color"
              style={{ background: performanceStatChartColors[name][index] }}
            />
            <span className="stat-chart-info">
              <span className="stat-chart-info-label">{stat}: </span>
              <span className="stat-chart-info-value">{value}</span>
            </span>
          </FlexBox>
        ))}
      </FlexBox>
      <PieChart
        rounded
        totalValue={totalValue}
        data={[data]}
        background={performanceStatChartColors[name][1]}
        className="stat-chart"
        lineWidth={20}
        labelPosition={0}
        startAngle={0}
        lengthAngle={360}
        labelStyle={{
          fontSize: 20,
          fontWeight: 700,
        }}
        label={() =>
          name === EPerformanceChartStat.BUDGET
            ? compactNumberConverter(totalValue, 'currency')
            : totalValue
        }
      />
    </FlexBox>
  );
}
