import React from 'react';

import { FlexBox } from '@eltoro-ui/components';

import OrderlineDetailsContainer from 'Pages/OrderlineDetails/components/OrderlineDetailsContainer';
import AudienceCard from 'Pages/OrderlineDetails/components/AttachedAudiences/components/AudienceCard';

import type { TOrderlineDetails } from 'types';

interface AttachedAudiencesProps extends Pick<TOrderlineDetails, 'targets' | 'status'> {}

export default function AttachedAudiences({ targets, status }: AttachedAudiencesProps) {
  return (
    <OrderlineDetailsContainer title="Attached Audiences" status={status}>
      <FlexBox flexDirection="column" gap="0.75rem">
        {targets.map(target => (
          <AudienceCard key={target.id} target={target} />
        ))}
      </FlexBox>
    </OrderlineDetailsContainer>
  );
}
