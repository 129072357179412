import type { Dispatch, SetStateAction } from 'react';
import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import toast from 'react-hot-toast';
import moment from 'moment';

import { Menu } from 'primereact/menu';
import type { MenuItem } from 'primereact/menuitem';
import type { ButtonProps } from 'primereact/button';
import { Button as PrimeButton } from 'primereact/button';

import RenameOrderlineModal from 'Components/RenameOrderlineModal';
import OrderlineActionModal from 'Pages/OrderlineDetails/components/OrderlineHeader/components/OrderlineActions/components/OrderlineActionModal/OrderlineActionModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';

import { orderlineActionItems } from 'Pages/OrderlineDetails/constants';

import { pauseOrderline, playOrderline } from 'Requests/Request_Methods/orderlineMethods';

import { OrderlineActionsEnum, OrderlineStatusEnum } from 'enums';
import type { TOrderlineDetails } from 'types';

interface OrderlineActionsProps extends Pick<TOrderlineDetails, 'name' | 'status' | 'end_date'> {
  setOrderline: Dispatch<SetStateAction<TOrderlineDetails | null>>;
  getOrderline: () => Promise<void>;
  generatePDF: () => Promise<void>;
}

export default function OrderlineActions({
  name,
  status,
  end_date,
  setOrderline,
  getOrderline,
  generatePDF,
}: OrderlineActionsProps) {
  const { orderlineId } = useParams<{ orderlineId: string }>();
  const history = useHistory();

  const [orderlineActionLoading, setOrderlineActionLoading] = useState(false);

  const [renameOrderlineModalOpen, setRenameOrderlineModalOpen] = useState(false);
  const [activeOrderlineAction, setActiveOrderlineAction] = useState<
    OrderlineActionsEnum.RESUME | OrderlineActionsEnum.PAUSE | null
  >(null);

  const orderlineActionRef = useRef<Menu>(null);
  const menuRootWrapperRef = useRef<HTMLDivElement>(null);

  const actionsCommand = {
    [OrderlineActionsEnum.CONTACT_SUPPORT]: () => history.push('/profile/notifications'),
    [OrderlineActionsEnum.RESUME]: () => setActiveOrderlineAction(OrderlineActionsEnum.RESUME),
    [OrderlineActionsEnum.PAUSE]: () => setActiveOrderlineAction(OrderlineActionsEnum.PAUSE),
    [OrderlineActionsEnum.RENAME]: () => setRenameOrderlineModalOpen(true),
    [OrderlineActionsEnum.DOWNLOAD_PDF]: () => generatePDF(),
    [OrderlineActionsEnum.EDIT_DATES]: () => history.push('/profile/notifications'),
  };

  const toggleOrderlineActionSelect: ButtonProps['onClick'] = event => {
    if (orderlineActionRef && orderlineActionRef.current) {
      orderlineActionRef.current.toggle(event);
    }
  };

  const items: MenuItem[] = orderlineActionItems
    .filter(action => {
      if (action.accessStatuses.includes(status) && status === OrderlineStatusEnum.PAUSED) {
        if (action.flightDatePassed === undefined) return true;
        if (moment(end_date).isBefore(moment())) return action?.flightDatePassed;
        return !action?.flightDatePassed;
      }

      return action.accessStatuses.includes(status);
    })
    .map(({ accessStatuses, ...item }) => ({
      ...item,
      command: actionsCommand[item.label],
    }));

  const updateOrderlineAfterSuccessAction = () => getOrderline();

  const onCloseActionModal = () => setActiveOrderlineAction(null);

  const onConfirmOrderlineAction = async () => {
    try {
      setOrderlineActionLoading(true);
      const { data } = await (activeOrderlineAction === OrderlineActionsEnum.PAUSE
        ? pauseOrderline
        : playOrderline)(orderlineId);

      if (data) {
        toast.success(data.message);
        onCloseActionModal();
        updateOrderlineAfterSuccessAction();
      }
    } catch (e) {
      if (e.detail) toast.error(e.detail);
      onCloseActionModal();
    } finally {
      setOrderlineActionLoading(false);
    }
  };

  return (
    <div ref={menuRootWrapperRef} className="orderline-actions-wrapper">
      <Menu
        className="orderline-actions-menu"
        ref={orderlineActionRef}
        popup
        id="popup_menu_right"
        popupAlignment="right"
        model={items}
      />
      <PrimeButton
        className="orderline-action-btn"
        icon={<FontAwesomeIcon icon={faEllipsisV} color="#757575" style={{ fontSize: 20 }} />}
        onClick={toggleOrderlineActionSelect}
        aria-controls="popup_menu_right"
        aria-haspopup
      />
      {activeOrderlineAction && (
        <OrderlineActionModal
          action={activeOrderlineAction}
          onCancel={onCloseActionModal}
          onConfirm={onConfirmOrderlineAction}
          loading={orderlineActionLoading}
        />
      )}
      {renameOrderlineModalOpen && (
        <RenameOrderlineModal
          orderline_uuid={orderlineId}
          name={name}
          onSuccess={orderlineName => {
            setOrderline(orderline => {
              if (!orderline) return orderline;
              return { ...orderline, name: orderlineName };
            });
          }}
          onCancel={() => setRenameOrderlineModalOpen(false)}
        />
      )}
    </div>
  );
}
