import React from 'react';

import { Modal } from '@eltoro-ui/components';
import { Spinner } from 'Components';

import { useMagicCreativeContext } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/context/MagicCreativeContext';

import StepSider from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/StepSider';
import MagicCreativeContent from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent';

import cross from 'assets/Images/cross.png';

import { TCreative } from 'types';
import { CreativeTypeEnum } from 'enums';

import './MagicCreativeGenerator.scss';

interface MagicCreativeGeneratorProps {
  onCancel: VoidFunction;
  onConfirm: (creative: TCreative[], typeModal: CreativeTypeEnum) => void;
}

export default function MagicCreativeGenerator({
  onCancel,
  onConfirm,
}: MagicCreativeGeneratorProps) {
  const { defaultUserDataLoading } = useMagicCreativeContext();

  return (
    <Modal className="magic-creative-generator-modal">
      {defaultUserDataLoading && <Spinner />}
      <img
        src={cross}
        onClick={onCancel}
        alt="cross"
        className="PersonalInfoForm__cross"
        role="presentation"
      />
      <StepSider />
      <MagicCreativeContent onConfirm={onConfirm} />
    </Modal>
  );
}
