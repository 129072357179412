import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import toast from 'react-hot-toast';

import { Button } from '@eltoro-ui/components';

import { usePermissionContext } from 'contexts/PermissionContext';

import {
  ConfirmationModal,
  EmptyStatus,
  PageHeader,
  ReactTable,
  ReactTableSearchBar,
} from 'Components';

import DeactivateInfo from 'Pages/MyProfile/components/DeactivateAccount/components/DeactivateInfo';
import RenameOrderlineModal from 'Components/RenameOrderlineModal';

import { getOrderlines, getTotalCounts } from 'Requests/Request_Methods/campaignMethods';

import {
  getCampaignColumns,
  getOrderlineColumns,
  redirectCampaign,
  redirectOrderline,
} from 'Pages/Orderlines/helpers';

import { deleteCampaign, getCampaigns } from 'Requests';

import { Tabs } from 'enums';
import type { TCampaign, TOrderline, TResPagination, TRootState } from 'types';

import './Orderlines.scss';

export default function Orderlines() {
  const { flagsReady } = useFlagsStatus();
  const orderlineViewFlag = useFlag('orderline-view');
  const location = useLocation();
  const history = useHistory();
  const { userIsDeactivatedWithActiveOrderlines, userIsFailed, userIsActive } =
    usePermissionContext();

  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.ORDERLINES);

  const [campaigns, setCampaigns] = useState<TCampaign[]>([]);
  const [orderlines, setOrderlines] = useState<TOrderline[]>([]);
  const [totals, setTotals] = useState({ orderine: 0, camapign: 0 });
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteCampaignData, setDeleteCampaignData] = useState<TCampaign[]>([]);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [campaignPage, setCampaignPage] = useState<number>(1);
  const [orderlinePage, setOrderlinePage] = useState<number>(1);
  const [campaignPagination, setCampaignPagination] = useState<TResPagination>({
    total_count: 0,
    total_pages: 0,
  });
  const [orderlinePagination, setOrderlinePagination] = useState<TResPagination>({
    total_count: 0,
    total_pages: 0,
  });
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [currentOrderline, setCurrentOrderline] = useState<null | TOrderline>(null);
  const { setGlobalFilters } = useSelector((state: TRootState) => state.setGlobalFilters);
  const { globalFilters } = useSelector((state: TRootState) => state.globalFilters);

  const _fetchCampaignData = async () => {
    setFetchDataIsLoading(true);
    try {
      const res = await getCampaigns(campaignPage, 50, null, null);
      if (res.data) {
        const { total_campaigns, total_pages, campaigns } = res.data;
        setCampaigns(campaigns);
        setCampaignPagination({ total_count: total_campaigns, total_pages });
      }
      setFetchDataIsLoading(false);
    } catch (err: any) {
      toast.error(err.detail);
      setFetchDataIsLoading(false);
    }
  };

  const _fetchTabsTotalCounts = async () => {
    const res = await getTotalCounts();
    if (res.data)
      setTotals({ orderine: res.data.orderline_count, camapign: res.data.campaign_count });
  };
  const _fetchOrderlineData = async () => {
    setFetchDataIsLoading(true);
    try {
      const res = await getOrderlines(orderlinePage, 50, null, null);
      if (res.data) {
        const { total_orderlines, total_pages, orderlines } = res.data;
        setOrderlines(orderlines);
        setOrderlinePagination({ total_count: total_orderlines, total_pages });
      }
      setFetchDataIsLoading(false);
    } catch (err: any) {
      toast.error(err.detail || 'Something went wrong!');
      setFetchDataIsLoading(false);
    }
  };

  useEffect(() => {
    _fetchTabsTotalCounts();
    if (activeTab === Tabs.CAMPAIGNS) {
      _fetchCampaignData();
    } else {
      _fetchOrderlineData();
    }
  }, [campaignPage, orderlinePage, activeTab, location.state?.refreshData]);

  const onDelete = async (_items: TCampaign[]) => {
    setDeleteIsLoading(true);
    const ids = _items.map((item: any) =>
      deleteCampaignData.length ? item?.id : item?.original?.id
    );
    try {
      const { data } = await deleteCampaign({ campaign_id: ids });
      if (data?.length)
        toast.success(`${data.length} Campaign${data.length > 1 ? 's' : ''} Deleted`);
      _fetchTabsTotalCounts();
      if (deleteCampaignData.length) {
        setDeleteCampaignData([]);
      } else {
        setDeleteModalIsOpen(false);
      }

      setDeleteIsLoading(false);

      if (campaignPage === 1) await _fetchCampaignData();
      else await setCampaignPage(1);
    } catch (err: any) {
      toast.error(err.detail);
      setDeleteIsLoading(false);
    }
  };

  const handleOpenRenameModal = (open: boolean, orderline: TOrderline | null) => {
    setRenameModalOpen(open);
    setCurrentOrderline(orderline);
  };

  const updateCreativeAfterEditOrderlineName = ({
    orderline_uuid,
    name,
  }: Pick<TOrderline, 'name' | 'orderline_uuid'>) =>
    setOrderlines(orderlines =>
      orderlines.map(orderline =>
        orderline.orderline_uuid === orderline_uuid ? { ...orderline, name } : { ...orderline }
      )
    );

  const changeRoutePath = (actionItem: TCampaign | TOrderline) => {
    if ('step' in actionItem) {
      history.push(redirectCampaign(actionItem));
    }
    if ('orderline_uuid' in actionItem) {
      history.push(redirectOrderline(actionItem));
    }
  };

  return (
    <div className="Campaigns">
      <PageHeader
        UNSAFE_CLASSNAME="Campaigns__PageHeader"
        LeftContent_ClassName="MyListings__header"
        subTitle={
          <span className="MyListings__totalCounts">
            You have{' '}
            {activeTab === Tabs.CAMPAIGNS ? (
              <b>
                {campaignPagination.total_count} campaign
                {campaignPagination.total_count > 1 ? 's' : ''}
              </b>
            ) : (
              <b>
                {orderlinePagination.total_count} orderline
                {orderlinePagination.total_count > 1 ? 's' : ''}
              </b>
            )}
          </span>
        }
        actions={[
          <ReactTableSearchBar
            key={activeTab}
            globalFilter={globalFilters}
            setGlobalFilter={setGlobalFilters}
            placeholder={
              activeTab === Tabs.ORDERLINES ? 'Search by orderline' : 'Search by campaign'
            }
          />,
          <Button
            key="new-campaign"
            kind="primary"
            to="?action=create-campaign"
            replace
            size="l"
            weight="bold"
            disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
          >
            New Campaign
          </Button>,
        ]}
      />
      <DeactivateInfo />
      {flagsReady && orderlineViewFlag && (
        <div className="table-tabs">
          <button
            onClick={() => setActiveTab(Tabs.ORDERLINES)}
            className={activeTab === Tabs.ORDERLINES ? 'active' : 'inactive'}
          >
            Orderlines ({totals.orderine})
          </button>
          <button
            onClick={() => setActiveTab(Tabs.CAMPAIGNS)}
            className={activeTab === Tabs.CAMPAIGNS ? 'active' : 'inactive'}
          >
            Campaigns ({totals.camapign})
          </button>
        </div>
      )}
      {activeTab === Tabs.CAMPAIGNS ? (
        <ReactTable<TCampaign>
          title="campaign"
          loading={fetchDataIsLoading}
          deleteModalNote="Only draft campaigns can be deleted"
          deleteModalHeader="Are you sure you want to delete the selected campaign(s)?"
          emptyText={
            <EmptyStatus
              heading="You don’t have any campaigns yet."
              subHeading="You can try by creating new campaign to get better audience."
            />
          }
          data={campaigns}
          onDelete={onDelete}
          deleteModal={deleteModalIsOpen}
          setDeleteModal={setDeleteModalIsOpen}
          deleteIsLoading={deleteIsLoading}
          pageSize={50}
          totalCount={campaignPagination.total_count}
          pageCount={campaignPagination.total_pages}
          currentPage={campaignPage}
          setPage={setCampaignPage}
          columns={getCampaignColumns(userIsActive, changeRoutePath, (campaign: TCampaign) =>
            setDeleteCampaignData([campaign])
          )}
        />
      ) : (
        <ReactTable<TOrderline>
          title="orderline"
          loading={fetchDataIsLoading}
          emptyText={
            <EmptyStatus
              heading="You don’t have any orderlines yet."
              subHeading="Your orderline list will appear here once you create campaigns."
            />
          }
          data={orderlines}
          pageSize={50}
          selectAllFeature={false}
          selectFeature={false}
          totalCount={orderlinePagination.total_count}
          pageCount={orderlinePagination.total_pages}
          currentPage={orderlinePage}
          setPage={setOrderlinePage}
          columns={getOrderlineColumns(userIsActive, handleOpenRenameModal, changeRoutePath)}
        />
      )}
      {deleteCampaignData.length === 1 && (
        <ConfirmationModal
          heading="Are you sure you want to delete the selected campaign(s)?"
          note="Only draft campaigns can be deleted"
          onSubmit={() => onDelete(deleteCampaignData)}
          onCancel={() => setDeleteCampaignData([])}
          loading={deleteIsLoading}
          okText="Yes, Delete"
          cancelText="No, Thanks"
          deletion
        />
      )}
      {renameModalOpen && currentOrderline && (
        <RenameOrderlineModal
          orderline_uuid={currentOrderline.orderline_uuid}
          name={currentOrderline.name}
          onSuccess={async name => {
            await updateCreativeAfterEditOrderlineName({
              orderline_uuid: currentOrderline.orderline_uuid,
              name,
            });
          }}
          onCancel={() => handleOpenRenameModal(false, null)}
        />
      )}
    </div>
  );
}
