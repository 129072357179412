import React, { useEffect } from 'react';

import toast from 'react-hot-toast';

import { Button, FlexBox } from '@eltoro-ui/components';

import { useMagicCreativeContext } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/context/MagicCreativeContext';

import ChooseGoal from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/ChooseGoal';
import FillDetails from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails';
import MagicBannersUsage from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/MagicBannersUsage';

import {
  EMagicGeneratorStep,
  steps,
} from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/constants';

import {
  generatedBannersUse,
  generateMagicCreatives,
} from 'Requests/Request_Methods/creativeMethods';

import { TCreative } from 'types';
import { CreativeTypeEnum } from 'enums';

const stepContentRender = (stepLabel: EMagicGeneratorStep) => {
  if (stepLabel === EMagicGeneratorStep.CHOOSE_GOAL) return <ChooseGoal />;
  if (stepLabel === EMagicGeneratorStep.FILL_DETAILS) return <FillDetails />;
  if (stepLabel === EMagicGeneratorStep.USE_BANNERS) return <MagicBannersUsage />;
  return null;
};

interface MagicCreativeContentProps {
  onConfirm: (creative: TCreative[], typeModal: CreativeTypeEnum) => void;
}

export default function MagicCreativeContent({ onConfirm }: MagicCreativeContentProps) {
  const {
    formState: [formState],
    isValidForm: [isValidForm],
    generateHasError: [, setHasError],
    generateLoading: [bannersLoading, setGenerateLoading],
    useGeneratedBannersLoading: [useBannersLoading, setUseBannersLoading],
    generatedBanners: [banners, setGeneratedBanners],
    selectedGoal,
    activeStep,
    onStepChange,
  } = useMagicCreativeContext();

  useEffect(() => {
    if (activeStep.label !== 'Use Magic Banners') {
      setGeneratedBanners([]);
      setHasError(false);
    }
  }, [activeStep]);

  const generateBanners = async () => {
    try {
      setGenerateLoading(true);
      const formData = new FormData();
      const {
        agentPhoto,
        listingPhoto,
        agencyLogo,
        agent_name,
        agent_phone,
        real_estate_agency,
        ...form
      } = formState;

      const additional_data =
        form.type === 'agent'
          ? {
              agent_word: form.agent_word,
              team_slogan: form.team_slogan,
            }
          : {
              listing_address: form.listing_address,
              bath: form.bath,
              bed: form.bed,
              sqft: form.sqft,
            };

      const body = {
        agent_name,
        agent_phone,
        real_estate_agency,
        goal: selectedGoal.name,
        additional_data,
        ...(form.type === 'agent'
          ? { background_color: [...form.background_color.match(/\d+/g)?.map(Number), 255] }
          : {}),
      };

      formData.append('agent_photo', agentPhoto as File);
      formData.append('listing_photo', listingPhoto as File);
      formData.append('agency_logo', agencyLogo as File);

      formData.append('body', JSON.stringify(body));

      const { data } = await generateMagicCreatives(formData);
      if (data) setGeneratedBanners(data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setGenerateLoading(false);
    }
  };

  const onUseGeneratedBanners = async () => {
    try {
      setUseBannersLoading(true);
      const { data } = await generatedBannersUse(banners.map(banner => banner.banner_uuid));
      if (data) onConfirm(data.creatives, CreativeTypeEnum.BANNER);
    } catch (e) {
      toast.error('Oops, Failed to Upload Your Banners.');
    } finally {
      setUseBannersLoading(false);
    }
  };

  const disabled =
    activeStep.label === 'Fill in the Details'
      ? !isValidForm
      : activeStep.label === 'Use Magic Banners'
      ? !banners.length
      : false;

  const onContinue = () => {
    onStepChange(steps[1]);
  };

  const onGenerateBanners = () => {
    onStepChange(steps[2]);
    generateBanners();
  };

  const onSubmit =
    activeStep.label === EMagicGeneratorStep.CHOOSE_GOAL
      ? onContinue
      : activeStep.label === EMagicGeneratorStep.FILL_DETAILS
      ? onGenerateBanners
      : activeStep.label === EMagicGeneratorStep.USE_BANNERS
      ? onUseGeneratedBanners
      : () => {};

  const submitLoading =
    activeStep.label === EMagicGeneratorStep.FILL_DETAILS
      ? bannersLoading
      : activeStep.label === EMagicGeneratorStep.USE_BANNERS
      ? useBannersLoading
      : false;

  return (
    <div className="creative-generator-modal-content">
      <FlexBox flexDirection="column" justifyContent="space-between">
        <FlexBox
          UNSAFE_className="creative-generator-modal-content-inner"
          flexDirection="column"
          gap="20px"
        >
          {stepContentRender(activeStep.label)}
        </FlexBox>
        <FlexBox
          alignItems="center"
          justifyContent="flex-end"
          UNSAFE_className="creative-generator-modal-content-footer"
        >
          <Button
            kind="primary"
            weight="bold"
            size="l"
            onClick={onSubmit}
            disabled={disabled}
            loading={submitLoading}
          >
            {activeStep.submitText}
          </Button>
        </FlexBox>
      </FlexBox>
    </div>
  );
}
